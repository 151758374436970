import React from 'react';

import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';

export default function LegacySection({ heading, content, contentNode, ctas, customData }) {
  return (
    <Section {...JSON.parse(customData || '{}').flags}>
      <h2>{heading}</h2>
      {content && <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
      <InlineList centered>
        {ctas.map((cta, i) => {
          const ctaFlags = Object.assign({ secondary: i > 0 }, JSON.parse(cta.customData));

          return (
            <li key={i}>
              <Button to={cta.url} {...ctaFlags}>
                {cta.text}
              </Button>
            </li>
          );
        })}
      </InlineList>
    </Section>
  );
}
